import React from "react"
import Layout from "../components/layout"
import HeaderBanner from "../components/headerbanner"
import { graphql, Link } from "gatsby"
import SEOHeader from "../components/seo-header"
import Markdown from "react-markdown"
const PartnerPage = props => {
  const partner = props.data.partner
  return (
    <div>
      <SEOHeader
        title={partner.partnerTitle + " | Partner Perks of Gofloaters"}
        description={
          "GoFloaters doesn’t just save money on your workspaces, It can save money for your business. Meet our partner " +
          partner.partnerTitle +
          ". Benefit from their exclusive offer to you as a GoFloaters customer."
        }
        socialLocalURL="https://assets.gofloaters.com/socialimage/homepage.jpg"
        pinterest="true"
        socialURL=""
      ></SEOHeader>
      <Layout>
        <HeaderBanner>
          <h1>{partner.partnerTitle}</h1>
          <div className="col-md-12 breadcrumb-container text-center">
            <p>
              <Link to="/our-community-perks">Community Benefits</Link> /{" "}
              {partner.partnerTitle}
            </p>
          </div>
        </HeaderBanner>
        <div className="container">
          <div className="row">
            <div className="col-md-3 text-center">
              <img src={partner.partnerImg} alt="Amazon Web Services"></img>
            </div>
            <div className="col-md-9">
              <Markdown source={partner.partnerFullDesc} escapeHtml={false} />

              <p>
                <br />
                If you are part of our community and wish to avail this benefit
                <br />
                <br />
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdXHfsJmpLr7R3tcIdh55dRlWBYD245xIyvCVFPkd5pRJY9gQ/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=""
                  style={{
                    fontSize: "0.9rem",
                    color: "#fff",
                    background: "#4f9fc8",
                    borderRadius: "5px",
                    padding: "7px 15px",
                    marginTop: "10px",
                  }}
                >
                  Claim Now{" "}
                  <img
                    src="https://assets.gofloaters.com/arrow-icon.png"
                    alt="arrow"
                    style={{ width: "20px", paddingBottom: "3px" }}
                  />
                </a>
                <br /> <br />
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
export default PartnerPage

export const query = graphql`
  query PartnerPage($slug: String!) {
    partner(partnerSlug: { eq: $slug }) {
      order
      partnerDesc
      partnerFullDesc
      partnerImg
      partnerSlug
      partnerTitle
      id
    }
  }
`
